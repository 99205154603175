import React, { useState, useEffect } from 'react';

import { IoIosCheckmarkCircle } from 'react-icons/io';
import { MdDeleteForever } from 'react-icons/md';
import { FiCircle } from 'react-icons/fi';
import { clear } from '@testing-library/user-event/dist/clear';
import divider from './divider.svg';
import logolong from './preview.png';
import searchdivider from './searchdivider.svg';
import Popup from 'reactjs-popup';
const App = () => {
	const [isLogin, setIsLogin] = useState(true);
	const[activeguess,setActiveguess] = useState(false);
	const [todos, setTodos] = useState([]);
	const [current,setCurrent]=useState([{id: 0, guessnum:'', todo:'',simscore:'',gamewon:'',invocab:'',complete:false}]);
	const [isnotinvocab, setIsnotinvocab] = useState(false);
	const [isgamewon, setIsgamewon] = useState(false);
	const [isrepeat, setIsrepeat] = useState(false);
	const [todoItem, setTodoItem] = useState('');
	const [error, setError] = useState(false);
	const [twitterlink, setTwitterlink] = useState();
	const [completedTasks, setCompletedTasks] = useState('');
	const [puzzlenum,setPuzzlenum]=useState();
	const [one,setOne] = useState();
	const [ten, setTen] = useState();
	const [thousand, setThousand] = useState();
	const [targetword,setTargetword]=useState();
	const [hint, setHint] = useState();
	const [hint2, setHint2] = useState();
	const [hintrequest, setHintrequest] = useState();
	const [gaveup, setGaveup] = useState();
	const [enable, setEnable] = useState(true);
	let repeatcheck=[];
	let bg1;
	let bglengthstore;
	let bgkeep;
	let bglengthstorelow;
	let bglengthstoremid;
	let percentile;
	let currday;
	let twitterhtml;
	const [bglength, setBglength] = useState();
	let Today = new Date().toLocaleDateString('en-us', { weekday: 'long' });
	let day = new Date().toLocaleDateString('en-us', { day: 'numeric' });
	let month = new Date().toLocaleDateString('en-us', { month: 'short' });

	async function onloadpage(){
		let res = await fetch(`https://quiet-tor-99792.herokuapp.com/https://clinicguessapi.herokuapp.com/?word=nothing`);
		let pageloaddata = await res.json();
		setPuzzlenum(pageloaddata.Puzzlenum);
		setOne(pageloaddata.One);
		setTen(pageloaddata.Ten);
		setThousand(pageloaddata.Thousand);
		setHint(pageloaddata.Hint);
		setHint2(pageloaddata.Hint2);
		setTargetword(pageloaddata.SD);
		currday = Math.floor(Date.now()/1000/60/60/24-3/24-19376);
		console.log(currday)
		let localdata = JSON.parse(localStorage.getItem('todos'));
		let firstguess = localdata.filter((localdatum) => localdatum.guessnum === 1)
		if(firstguess.length!==0)
		{
			console.log(firstguess)
			let localday = Number(firstguess[0].id)/1000/60/60/24-3/24-19376;
			console.log(localday)
			localday = Math.floor(localday)
			if(currday !== localday){
				clearguesses();
			}
			else
			{
				
			}
		}
		else{
			
		}
	  }
	
	window.onload = function() {
		onloadpage();

	  };

	function getHint(){
		setHintrequest(true);
	}
	function getCapitulation(){
		setGaveup(true);
		setEnable(false);
	}
	async function handleSubmit(e) {
		e.preventDefault();
		if (enable === true)
		{

		
		
		let response = await fetch(`https://quiet-tor-99792.herokuapp.com/https://clinicguessapi.herokuapp.com/?word=${todoItem.toLowerCase()}`);
		let data = await response.json();
		let num = todos.length+1;
		repeatcheck = todos.filter(item => item.todo === data.Guess)
		if (data.GameWon === "True"){setIsgamewon(true)

			if (repeatcheck.length !==0){
				let old_guess = todos.filter((item) => item.todo === data.Guess);
				let currentTodo = {

					guessnum: old_guess[0].guessnum,
					todo: old_guess[0].todo,
					simscore: old_guess[0].simscore,
					gamewon: old_guess[0].gamewon,
					invocab: old_guess[0].invocab,
					complete: false,
					bgcode: old_guess[0].bgcode,
					percentile: old_guess[0].percentile,
					puzzlenum: old_guess[0].puzzlenum,
					wordorder: old_guess[0].wordorder,
				};
				setCurrent([currentTodo]);
				setActiveguess(true);
				twitterhtml = "https://twitter.com/intent/tweet?text=I%20solved%20the%20semantic%20medical%20guessing%20game,%20%40ClinicleGame%20Puzzle%20%23"+String(old_guess[0].puzzlenum)+",%20in%20"+String(old_guess[0].guessnum)+"%20guesses!%0A%0ASee%20if%20you%20can%20beat%20me!%0Ahttps%3A//www.cliniclegame.com%0A%0A%23ClinicleGame%20%23MedTwitter%20%23SciTwitter%20%23Medicine%20%23Puzzles";
				console.log(twitterhtml)
			}
			else{
				twitterhtml = "https://twitter.com/intent/tweet?text=I%20solved%20the%20semantic%20medical%20guessing%20game,%20%40ClinicleGame%20Puzzle%20%23"+String(data.Puzzlenum)+",%20in%20"+String(num)+"%20guesses!%0A%0ASee%20if%20you%20can%20beat%20me!%0Ahttps%3A//www.cliniclegame.com%0A%0A%23ClinicleGame%20%23MedTwitter%20%23SciTwitter%20%23Medicine%20%23Puzzles";

			}
			setTwitterlink(twitterhtml)
		}else{setIsgamewon(false)}
		setError(false);
		let uniqueId =
			new Date().getTime().toString(36) + new Date().getUTCMilliseconds();
		uniqueId=Date.now();
		if (todoItem) {
			if(data.InVocab === "False"){setIsnotinvocab(true)}else{
				setIsnotinvocab(false)
				if(repeatcheck.length ===0){
					setIsrepeat(false)
					bg1 = (100-(100*(50000-Math.max(50000-Number(data.WordOrder),0))/50000));
					bglengthstore ={background:'linear-gradient(to right, #8de359 '+String(bg1.toFixed(0))+'%, #fff '+String(Number(bg1.toFixed(0))+0.5)+'%)'};
					bglengthstoremid ={background:'linear-gradient(to right, #e3c359 '+String(bg1.toFixed(0))+'%, #fff '+String(Number(bg1.toFixed(0))+0.5)+'%)'};
					bglengthstorelow={background:'linear-gradient(to right, #e35959 '+String(bg1.toFixed(0))+'%, #fff '+String(Number(bg1.toFixed(0))+0.5)+'%)'};
					if (bg1<=30){
						console.log(bg1+100)
						setBglength(bglengthstorelow);
						bgkeep=bglengthstorelow;
					}
					else{
						if(bg1<=70){
							console.log(bg1+1000)
							setBglength(bglengthstoremid);
							bgkeep=bglengthstoremid;
						}
						else{
							console.log(bg1+10000)
							setBglength(bglengthstore);
							bgkeep=bglengthstore;
						}
					}
					
					percentile = bg1.toFixed(1);
					console.log(percentile)
					let newTodoItem = {
						id: uniqueId,
						guessnum: num,
						todo: data.Guess,
						simscore: Number(data.Simscore),
						gamewon: data.GameWon,
						invocab: data.InVocab,
						complete: false,
						bgcode: bgkeep,
						percentile: percentile,
						puzzlenum: data.Puzzlenum,
						wordorder: data.WordOrder
					};
					setTodos([newTodoItem, ...todos]);
					setTodoItem('');
					setCurrent([newTodoItem]);
					setActiveguess(true);
				}
				else{
					setIsrepeat(true)
					let old_guess = todos.filter((item) => item.todo === data.Guess);
					let currentTodo = {
						id: uniqueId,
						guessnum: old_guess[0].guessnum,
						todo: old_guess[0].todo,
						simscore: old_guess[0].simscore,
						gamewon: old_guess[0].gamewon,
						invocab: old_guess[0].invocab,
						complete: false,
						bgcode: old_guess[0].bgcode,
						percentile: old_guess[0].percentile,
						puzzlenum: old_guess[0].puzzlenum,
						wordorder: old_guess[0].wordorder
					};
					setCurrent([currentTodo]);
					setActiveguess(true);

				}
			}
			
		} else {
			setError(true);
			setTodoItem('');
		}
	}

	};

	function clearguesses() {
		localStorage.removeItem('todos');
		setTodos([]);
		setCurrent([]);
	}
	const deleteTodo = (id) => {
		let newTodos = todos.filter((todo) => todo.id !== id);
		setTodos([...newTodos]);
	};

	const toggleComplete = (id) => {
		todos.find((todo) => {
			if (todo.id === id) {
				todo.complete = !todo.complete;
			}
			return setTodos([...todos]);
		});
	};

	useEffect(() => {
		let completeArray = [];
		todos.filter((todo) => todo.complete === true && completeArray.push(todo));
		setCompletedTasks(completeArray.length);
	}, [todos]);

	useEffect(() => {
		const todos = JSON.parse(localStorage.getItem('todos'));
		if (todos) {
			setTodos(todos);
		}
	}, []);

	useEffect(() => {
		let adderror = setTimeout(() => {
			setError(false);
		}, 2000);
		return () => {
			clearTimeout(adderror);
		};
	}, [error]);

	useEffect(() => {
		localStorage.setItem('todos', JSON.stringify(todos));
	}, [todos]);



	return (
		<div className="App">
      <head className="App-header">


      <link href='https://fonts.googleapis.com/css?family=Gothic A1' rel='stylesheet'></link>
      </head>
	  <div className="App-header">

<link href='https://fonts.googleapis.com/css?family=Gothic A1' rel='stylesheet'></link>
<img src={logolong} class ="imgpadded" />
<div class="buttoncontainer">

<div class = "normaltop"><img src={searchdivider} class="shrink"/></div>

<div class = "normalbottom"><img src={searchdivider} class="shrink2"/></div>
<div class = "searchtext" >Today is puzzle #<b>{puzzlenum}</b>. Guess medical terms. The closer you get to the secret diagnosis, the higher your score will be. Guess the secret word to win.</div>

<div class = "ccsearchtext" ><b>Prompt: </b>{hint}</div>
{hintrequest && (<div class = "ccsearchtext" ><b>Hint: </b>{hint2}</div>)}

		<form onSubmit={handleSubmit} class = "searchbox">
			<input
				type="text"
				value={todoItem}
				class="form-control"
				onChange={(e) => setTodoItem(e.target.value)}
				placeholder="Enter a guess"
			/>
			<button type="submit" class="btn btn-primary btn-block">
				Guess
			</button>
			<button onClick={getHint} class = "btn btn-primary btn2-block">Hint</button>
			<button onClick={getCapitulation} class = "btn btn-primary btn3-block">Give Up</button>
		</form>
			
		
</div>

<div class = "buttoncontainer">
<table class="tablealert">
{enable && isgamewon && (<tr><th bgcolor='#5fd467' align='center' >YOU WON THE GAME! CONGRATS!</th></tr>)}
{gaveup && (<tr><th bgcolor='#ffbab5' align='center' >The secret diagnosis was “{targetword}”. Try again tomorrow!</th></tr>)}
{enable && isgamewon && (<tr><th bgcolor="#1b95e0" align='center' class = "twb"><a href={twitterlink} class="twitter2-follow-button" data-show-count="false">Share your score on Twitter</a></th></tr>)}
<tr>{enable && isnotinvocab && (<th bgcolor='#F3A2A2' align='center' >This guess is not in my vocabulary. Please try again!</th>)}</tr>
<tr>{enable && isrepeat && (<th bgcolor='#ccc' align='center' >You already guessed this. Please try again!</th>)}</tr>
</table>

{enable && activeguess && (current.map((todoItem) => {
	const { id, guessnum, todo, simscore, invocab, gamewon, percentile, bgcode, wordorder } = todoItem;
	return (
		<div key={id}>
			<table class="progress"><td style={bgcode}><font color="#243d1d"><div class="lalign">{guessnum}. {todo}</div><div class="ralign">{percentile}</div></font></td></table>
		</div>
	);
}))}


	<img src={divider} class="imgpaddedline"/>
	{todos.sort((a, b) => b.simscore - a.simscore).map((todoItem) => {
		const { id, guessnum, todo, simscore, invocab, gamewon, percentile, bgcode } = todoItem;
		return (
			<div key={id}>
				<table class="progress"><td style={bgcode}><font color="#243d1d"><div class="lalign">{guessnum}. {todo}</div><div class="ralign">{percentile}</div></font></td></table>
			</div>
		);
	})}
</div>

<div class = "footercontainer">
<div class = "navtext"><Popup trigger={<button class="btnnav">HOW DO I PLAY?</button>} position="top center">
<div><b><u>How Do I Play?</u></b></div>
<div><br></br>The goal is to guess the secret medical diagnosis. The secret diagnosis may be a single or multi-word diagnosis but will never be an abbreviation. 
Each of your guesses can be a medical term that is one or multiple words. Some words and phrases may not be in the vocabulary.</div>
<div><br></br><b>Closeness Score</b></div>
<div>Clinicle will give you a numerical score that represents how “semantically close” your guess is to the secret diagnosis. 
The score isn’t based on spelling or character similarity, but rather conceptual similarity. 
For example, “heart” will be closer to “cardiac” than “hear” despite almost being identically spelled. The highest possible score is 100 and the lowest is 0. 
Guessing diagnoses will likely work better than guessing symptoms or organ systems.</div>

<div><br></br>
<b>There's a new secret diagnosis every day, where a day starts at 9:00 PM Chicago time.</b></div>
</Popup>
<Popup trigger={<button class="btnnav">HOW DID YOU MAKE THIS?</button>} position="top center">
<div><b><u>How Did You Make This?</u></b></div>
<div><br></br>Developing a semantic similarity score requires vector embeddings of tokens. In our case, a token can be a single word or a phrase. I initially attempted to create my own language model and create my own embeddings, but my single NVIDIA GPU couldn't handle the load.<br></br><br></br>Instead, I found several great papers and github repos of previously developed medical concept embeddings:<br></br><br></br>1. Stanford UMLS Embedding<br></br>2. Penn BioBERT Embeddings<br></br>3. AllenAI SciSpacy Embeddings</div>
<div><br></br>I ultimately used the Penn Clinical Word Embeddings developed by the Weissman lab. They used several approaches (word2vec, fastText, GloVe) trained on either 1. OpenAccess case reports only or 2. all OpenAccess publications. The best performing one for my use case was a 600-parameter word2vec model trained on OpenAccess case reports alone.</div>
</Popup>
<Popup trigger={<button class="btnnav">SOURCES</button>} position="top center">
<div><b><u>Sources</u></b></div>

<div><br></br><b>Inspiration</b></div>
<div><a href="https://semantle.com">Semantle</a> by David Turner</div>

<div><br></br><b>Medical Concept Embeddings</b></div>
<div><a href="https://github.com/gweissman/clinical_embeddings">Penn Clinical Word Embeddings</a> by Flamholz et. al.</div>
<div><a href="https://github.com/allenai/scispacy">ScispaCy: Fast and Robust Models for Biomedical Natural Language Processing</a> by Neumann et. al.</div>

<div><br></br><b>Tutorials</b></div>
<div><a href="https://webjeda.com/">WebJeda</a> for how to use Firebase</div>
<div><a href="https://www.youtube.com/@OllieHooper">Ollie Hooper</a> for how to deploy a Heroku Web App</div>
<div><a href="https://github.com/LintangWisesa/SocialLogin_React_Firebase">Lintang Wisesa</a> for social authentication with Firebase</div>
</Popup>

</div>
   <div class = "normallrg"><img src={divider} class="shrink"/></div>
<div class = "normallrg">Developed by <b><a href="https://twitter.com/PMylavarapuMD">@PMylavarapuMD</a></b>  |  Created 12.2022  |  <a href="https://twitter.com/ClinicleGame?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">Follow @ClinicleGame</a></div>
</div>
</div>      
    </div>
	);
};

export default App;
